import { graphql } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'
import React, { useEffect } from 'react'
import { Bio } from '../components/bio'
import * as Elements from '../components/elements'
import { Head } from '../components/head'
import { PostContainer } from '../components/post-container'
import { PostNavigator } from '../components/post-navigator'
import { PostTitle } from '../components/post-title'
import { SocialShare } from '../components/social-share'
import { Layout } from '../layout'
import '../styles/code.scss'
import * as ScrollManager from '../utils/scroll'

export default ({ data, pageContext, location }) => {
  useEffect(() => {
    ScrollManager.init()
    return () => ScrollManager.destroy()
  }, [])

  const post = data.markdownRemark
  const metaData = data.site.siteMetadata
  const { title, comment, siteUrl, author, sponsor } = metaData
  let disqusConfig = {
    identifier: post.id,
    title: post.frontmatter.title,
  }

  return (
    <Layout location={location} title={title}>
      <Head title={post.frontmatter.title} description={post.excerpt} />
      <PostTitle title={post.frontmatter.title} />
      <PostContainer html={post.html} />
      <SocialShare
        title={post.frontmatter.title}
        author={author}
        sponsor={sponsor}
      />
      <Elements.Hr />
      <h4>{post.frontmatter.date}</h4>
      <Bio />
      <PostNavigator pageContext={pageContext} />
      <Disqus config={disqusConfig} />{' '}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        sponsor {
          buyMeACoffeeId
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 280)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
